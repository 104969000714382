@import '~antd/dist/antd.less';
@import '~antd/dist/antd.dark.less';

.logo {
  float: left;
  width: 90px;
  height: 34px;
  margin: 16px 24px 16px 0;
  background-image: url(../public/rex-logo.png);
  background-size : contain;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@primary-color: #1DA57A;